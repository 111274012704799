import React, {lazy} from 'react';
// import logo from './logo.svg';
// import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

// import Home from "./pages/Home";
const Home = lazy(() => import("./pages/Home"));
const Products = lazy(() => import("./pages/Products"));
const Arts = lazy(() => import("./pages/Arts"));
const Fashion = lazy(() => import("./pages/Fashion"));
const Beauty = lazy(() => import("./pages/Beauty"));
const Retouch = lazy(() => import("./pages/Retouch"));
const Video = lazy(() => import("./pages/Video"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/ContactSimple"));
const MentionsLegales = lazy(() => import("./pages/MentionsLegales"));
const NotFound  = lazy(() => import("./pages/NotFound"));


export default function App() {
  return (
    <div>
      <BrowserRouter>
        <HttpsRedirect>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/Home' element={<Home/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/hireme' element={<About/>} />
            <Route path='/mentionslegales' element={<MentionsLegales/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/products' element={<Products/>} />
            <Route path='/retouch' element={<Retouch/>} />
            <Route path='/beauty' element={<Beauty/>} />
            <Route path='/fashion' element={<Fashion/>} />
            <Route path='/arts' element={<Arts/>} />
            <Route path='/video' element={<Video/>} />
            <Route element={<NotFound/>} />
          </Routes>
        </HttpsRedirect>
      </BrowserRouter>
    </div>
  );
}
